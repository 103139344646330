import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8367ebdc = () => interopDefault(import('../pages/address-book/index.vue' /* webpackChunkName: "pages/address-book/index" */))
const _25debde3 = () => interopDefault(import('../pages/company/index.vue' /* webpackChunkName: "pages/company/index" */))
const _4d0edd1a = () => interopDefault(import('../pages/company-panel/index.vue' /* webpackChunkName: "pages/company-panel/index" */))
const _71b90978 = () => interopDefault(import('../pages/contractors/index.vue' /* webpackChunkName: "pages/contractors/index" */))
const _50fffd18 = () => interopDefault(import('../pages/crews/index.vue' /* webpackChunkName: "pages/crews/index" */))
const _846e5faa = () => interopDefault(import('../pages/employees/index.vue' /* webpackChunkName: "pages/employees/index" */))
const _0f836724 = () => interopDefault(import('../pages/hazard-register/index.vue' /* webpackChunkName: "pages/hazard-register/index" */))
const _4ff0f387 = () => interopDefault(import('../pages/incidents/index.vue' /* webpackChunkName: "pages/incidents/index" */))
const _067059e8 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _85db6808 = () => interopDefault(import('../pages/machines/index.vue' /* webpackChunkName: "pages/machines/index" */))
const _11b41832 = () => interopDefault(import('../pages/reminders/index.vue' /* webpackChunkName: "pages/reminders/index" */))
const _3d919cd8 = () => interopDefault(import('../pages/safety-alerts/index.vue' /* webpackChunkName: "pages/safety-alerts/index" */))
const _48fddb5a = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _6d1a4b72 = () => interopDefault(import('../pages/sites/index.vue' /* webpackChunkName: "pages/sites/index" */))
const _d8ca23ee = () => interopDefault(import('../pages/summary.vue' /* webpackChunkName: "pages/summary" */))
const _19d5ec68 = () => interopDefault(import('../pages/summary/index.vue' /* webpackChunkName: "pages/summary/index" */))
const _6c56242c = () => interopDefault(import('../pages/summary/crews.vue' /* webpackChunkName: "pages/summary/crews" */))
const _3ae1965b = () => interopDefault(import('../pages/summary/incidents.vue' /* webpackChunkName: "pages/summary/incidents" */))
const _6c5c89b2 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _12c01854 = () => interopDefault(import('../pages/training-competency/index.vue' /* webpackChunkName: "pages/training-competency/index" */))
const _fb52a012 = () => interopDefault(import('../pages/vehicles/index.vue' /* webpackChunkName: "pages/vehicles/index" */))
const _327a420d = () => interopDefault(import('../pages/company-panel/view.vue' /* webpackChunkName: "pages/company-panel/view" */))
const _0dbe4ad0 = () => interopDefault(import('../pages/company-panel/view/index.vue' /* webpackChunkName: "pages/company-panel/view/index" */))
const _b775a51e = () => interopDefault(import('../pages/company-panel/view/edit-details.vue' /* webpackChunkName: "pages/company-panel/view/edit-details" */))
const _7e6ceccd = () => interopDefault(import('../pages/company-panel/view/manage-access/index.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/index" */))
const _eef48988 = () => interopDefault(import('../pages/company-panel/view/manage-crews.vue' /* webpackChunkName: "pages/company-panel/view/manage-crews" */))
const _29e24d0f = () => interopDefault(import('../pages/company-panel/view/manage-employees.vue' /* webpackChunkName: "pages/company-panel/view/manage-employees" */))
const _3411effa = () => interopDefault(import('../pages/company-panel/view/manage-access/view.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/view" */))
const _5d75da06 = () => interopDefault(import('../pages/company-panel/view/manage-access/view/index.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/view/index" */))
const _7af1a439 = () => interopDefault(import('../pages/company-panel/view/manage-access/view/groups.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/view/groups" */))
const _3136a424 = () => interopDefault(import('../pages/company/view.vue' /* webpackChunkName: "pages/company/view" */))
const _1a4f00b2 = () => interopDefault(import('../pages/company/view/index.vue' /* webpackChunkName: "pages/company/view/index" */))
const _54de9268 = () => interopDefault(import('../pages/company/view/files.vue' /* webpackChunkName: "pages/company/view/files" */))
const _ad3a7656 = () => interopDefault(import('../pages/company/view/objectives.vue' /* webpackChunkName: "pages/company/view/objectives" */))
const _c56a8da6 = () => interopDefault(import('../pages/company/view/policy.vue' /* webpackChunkName: "pages/company/view/policy" */))
const _1ac8b639 = () => interopDefault(import('../pages/employees/new.vue' /* webpackChunkName: "pages/employees/new" */))
const _87cbfed6 = () => interopDefault(import('../pages/incidents/new.vue' /* webpackChunkName: "pages/incidents/new" */))
const _3e814e6a = () => interopDefault(import('../pages/machines/add.vue' /* webpackChunkName: "pages/machines/add" */))
const _61c6ca26 = () => interopDefault(import('../pages/safety-alerts/new.vue' /* webpackChunkName: "pages/safety-alerts/new" */))
const _1f429c9a = () => interopDefault(import('../pages/sites/register.vue' /* webpackChunkName: "pages/sites/register" */))
const _1cc43414 = () => interopDefault(import('../pages/sites/register/index.vue' /* webpackChunkName: "pages/sites/register/index" */))
const _42efd3fb = () => interopDefault(import('../pages/sites/register/_forestid.vue' /* webpackChunkName: "pages/sites/register/_forestid" */))
const _36327f86 = () => interopDefault(import('../pages/vehicles/add.vue' /* webpackChunkName: "pages/vehicles/add" */))
const _3143c2b1 = () => interopDefault(import('../pages/reminders/goto/crewgear.vue' /* webpackChunkName: "pages/reminders/goto/crewgear" */))
const _a8a15304 = () => interopDefault(import('../pages/reminders/goto/employeegear.vue' /* webpackChunkName: "pages/reminders/goto/employeegear" */))
const _3ac7a696 = () => interopDefault(import('../pages/company-panel/manage-access/group/_groupuuid.vue' /* webpackChunkName: "pages/company-panel/manage-access/group/_groupuuid" */))
const _40644bac = () => interopDefault(import('../pages/company-panel/manage-access/user/_useruuid.vue' /* webpackChunkName: "pages/company-panel/manage-access/user/_useruuid" */))
const _39947b21 = () => interopDefault(import('../pages/company/program/_versionid/index.vue' /* webpackChunkName: "pages/company/program/_versionid/index" */))
const _2e0e2cf3 = () => interopDefault(import('../pages/company/program/_versionid/policy.vue' /* webpackChunkName: "pages/company/program/_versionid/policy" */))
const _f3d044d4 = () => interopDefault(import('../pages/employees/_id/index.vue' /* webpackChunkName: "pages/employees/_id/index" */))
const _92f21a1c = () => interopDefault(import('../pages/incidents/_id/index.vue' /* webpackChunkName: "pages/incidents/_id/index" */))
const _2372bea4 = () => interopDefault(import('../pages/sites/_siteid/index.vue' /* webpackChunkName: "pages/sites/_siteid/index" */))
const _eba8f2e2 = () => interopDefault(import('../pages/summarynew/_drillid.vue' /* webpackChunkName: "pages/summarynew/_drillid" */))
const _bc7c5456 = () => interopDefault(import('../pages/contractors/_companyid/agreement/index.vue' /* webpackChunkName: "pages/contractors/_companyid/agreement/index" */))
const _26a73ab4 = () => interopDefault(import('../pages/contractors/_companyid/reviews/index.vue' /* webpackChunkName: "pages/contractors/_companyid/reviews/index" */))
const _4f44a74a = () => interopDefault(import('../pages/crews/_crewid/documents.vue' /* webpackChunkName: "pages/crews/_crewid/documents" */))
const _5b450e4d = () => interopDefault(import('../pages/crews/_crewid/documents/index.vue' /* webpackChunkName: "pages/crews/_crewid/documents/index" */))
const _3dfd4572 = () => interopDefault(import('../pages/crews/_crewid/documents/files.vue' /* webpackChunkName: "pages/crews/_crewid/documents/files" */))
const _f4a98d62 = () => interopDefault(import('../pages/crews/_crewid/documents/gear-tracker.vue' /* webpackChunkName: "pages/crews/_crewid/documents/gear-tracker" */))
const _b7545492 = () => interopDefault(import('../pages/crews/_crewid/documents/safe-start.vue' /* webpackChunkName: "pages/crews/_crewid/documents/safe-start" */))
const _464c7b8f = () => interopDefault(import('../pages/crews/_crewid/documents/substance-inv.vue' /* webpackChunkName: "pages/crews/_crewid/documents/substance-inv" */))
const _9ba12ec2 = () => interopDefault(import('../pages/crews/_crewid/monthly.vue' /* webpackChunkName: "pages/crews/_crewid/monthly" */))
const _ec66883c = () => interopDefault(import('../pages/crews/_crewid/monthly/index.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/index" */))
const _286127d0 = () => interopDefault(import('../pages/crews/_crewid/monthly/audits.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/audits" */))
const _02152754 = () => interopDefault(import('../pages/crews/_crewid/monthly/emergency-drills.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/emergency-drills" */))
const _09ed72c3 = () => interopDefault(import('../pages/crews/_crewid/monthly/safety-meetings.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/safety-meetings" */))
const _7d41d57a = () => interopDefault(import('../pages/crews/_crewid/monthly/safety-review.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/safety-review" */))
const _618a5d13 = () => interopDefault(import('../pages/crews/_crewid/monthly/sbos.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/sbos" */))
const _341f8e66 = () => interopDefault(import('../pages/crews/_crewid/monthly/system-audits.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/system-audits" */))
const _65027206 = () => interopDefault(import('../pages/crews/_crewid/monthly/toolbox/index.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/toolbox/index" */))
const _9187b258 = () => interopDefault(import('../pages/crews/_crewid/monthly/toolbox/toolbox.unit.js' /* webpackChunkName: "pages/crews/_crewid/monthly/toolbox/toolbox.unit" */))
const _4c873af6 = () => interopDefault(import('../pages/employees/_id/edit.vue' /* webpackChunkName: "pages/employees/_id/edit" */))
const _34514211 = () => interopDefault(import('../pages/employees/_id/view.vue' /* webpackChunkName: "pages/employees/_id/view" */))
const _34239058 = () => interopDefault(import('../pages/employees/_id/view/index.vue' /* webpackChunkName: "pages/employees/_id/view/index" */))
const _1762cbee = () => interopDefault(import('../pages/employees/_id/view/compliance.vue' /* webpackChunkName: "pages/employees/_id/view/compliance" */))
const _3660234f = () => interopDefault(import('../pages/employees/_id/view/drug-alcohol.vue' /* webpackChunkName: "pages/employees/_id/view/drug-alcohol" */))
const _6eb3220e = () => interopDefault(import('../pages/employees/_id/view/files.vue' /* webpackChunkName: "pages/employees/_id/view/files" */))
const _fa0cb69e = () => interopDefault(import('../pages/employees/_id/view/forms.vue' /* webpackChunkName: "pages/employees/_id/view/forms" */))
const _1ad9d9c3 = () => interopDefault(import('../pages/employees/_id/view/induction.vue' /* webpackChunkName: "pages/employees/_id/view/induction" */))
const _040787f2 = () => interopDefault(import('../pages/employees/_id/view/linked.vue' /* webpackChunkName: "pages/employees/_id/view/linked" */))
const _7f397f93 = () => interopDefault(import('../pages/employees/_id/view/medical.vue' /* webpackChunkName: "pages/employees/_id/view/medical" */))
const _01cd6052 = () => interopDefault(import('../pages/employees/_id/view/ppe-tracker.vue' /* webpackChunkName: "pages/employees/_id/view/ppe-tracker" */))
const _031b439c = () => interopDefault(import('../pages/employees/_id/view/record-learning.vue' /* webpackChunkName: "pages/employees/_id/view/record-learning" */))
const _621e7086 = () => interopDefault(import('../pages/employees/_id/view/training-records.vue' /* webpackChunkName: "pages/employees/_id/view/training-records" */))
const _8d1bebcc = () => interopDefault(import('../pages/incidents/_id/edit.vue' /* webpackChunkName: "pages/incidents/_id/edit" */))
const _11adadc0 = () => interopDefault(import('../pages/machines/_machineid/edit.vue' /* webpackChunkName: "pages/machines/_machineid/edit" */))
const _126bebd1 = () => interopDefault(import('../pages/machines/_machineid/files.vue' /* webpackChunkName: "pages/machines/_machineid/files" */))
const _5a9010de = () => interopDefault(import('../pages/machines/_machineid/induction/index.vue' /* webpackChunkName: "pages/machines/_machineid/induction/index" */))
const _6c25e3fe = () => interopDefault(import('../pages/machines/_machineid/prepurchase/index.vue' /* webpackChunkName: "pages/machines/_machineid/prepurchase/index" */))
const _62af0743 = () => interopDefault(import('../pages/sites/_siteid/view.vue' /* webpackChunkName: "pages/sites/_siteid/view" */))
const _c9c860f4 = () => interopDefault(import('../pages/sites/_siteid/view/index.vue' /* webpackChunkName: "pages/sites/_siteid/view/index" */))
const _99ec6f76 = () => interopDefault(import('../pages/sites/_siteid/view/covid-qr.vue' /* webpackChunkName: "pages/sites/_siteid/view/covid-qr" */))
const _cb6daab8 = () => interopDefault(import('../pages/sites/_siteid/view/emergency-information.vue' /* webpackChunkName: "pages/sites/_siteid/view/emergency-information" */))
const _7dd406ab = () => interopDefault(import('../pages/sites/_siteid/view/files.vue' /* webpackChunkName: "pages/sites/_siteid/view/files" */))
const _207b992c = () => interopDefault(import('../pages/sites/_siteid/view/hazard-id.vue' /* webpackChunkName: "pages/sites/_siteid/view/hazard-id" */))
const _ca19299a = () => interopDefault(import('../pages/sites/_siteid/view/more-forms.vue' /* webpackChunkName: "pages/sites/_siteid/view/more-forms" */))
const _0e318f41 = () => interopDefault(import('../pages/sites/_siteid/view/visitors.vue' /* webpackChunkName: "pages/sites/_siteid/view/visitors" */))
const _73271480 = () => interopDefault(import('../pages/sites/_siteid/visitor.vue' /* webpackChunkName: "pages/sites/_siteid/visitor" */))
const _3ef9ac43 = () => interopDefault(import('../pages/sites/_siteid/visitor/index.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/index" */))
const _510887e4 = () => interopDefault(import('../pages/sites/_siteid/visitor/addressbook.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/addressbook" */))
const _622f6f58 = () => interopDefault(import('../pages/sites/_siteid/visitor/contractor.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/contractor" */))
const _58e6bc5c = () => interopDefault(import('../pages/sites/_siteid/visitor/infrequent.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/infrequent" */))
const _2a0d7356 = () => interopDefault(import('../pages/vehicles/_vehicleid/edit.vue' /* webpackChunkName: "pages/vehicles/_vehicleid/edit" */))
const _c656a918 = () => interopDefault(import('../pages/contractors/_companyid/reviews/new.vue' /* webpackChunkName: "pages/contractors/_companyid/reviews/new" */))
const _7fb33267 = () => interopDefault(import('../pages/crews/_crewid/audits/new.vue' /* webpackChunkName: "pages/crews/_crewid/audits/new" */))
const _372aee7c = () => interopDefault(import('../pages/crews/_crewid/emergency-drill/new.vue' /* webpackChunkName: "pages/crews/_crewid/emergency-drill/new" */))
const _79762320 = () => interopDefault(import('../pages/crews/_crewid/safe-start-stop/new.vue' /* webpackChunkName: "pages/crews/_crewid/safe-start-stop/new" */))
const _890782a2 = () => interopDefault(import('../pages/crews/_crewid/safety-meeting/new.vue' /* webpackChunkName: "pages/crews/_crewid/safety-meeting/new" */))
const _e6ab85de = () => interopDefault(import('../pages/crews/_crewid/safety-review/health-representative-review.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/health-representative-review" */))
const _16944654 = () => interopDefault(import('../pages/crews/_crewid/safety-review/new.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/new" */))
const _3fbd9211 = () => interopDefault(import('../pages/crews/_crewid/safety-review/site-supervisor-review.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/site-supervisor-review" */))
const _1c967ebd = () => interopDefault(import('../pages/crews/_crewid/safety-review/worker-review.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/worker-review" */))
const _18883ef3 = () => interopDefault(import('../pages/crews/_crewid/safety-sheets/add.vue' /* webpackChunkName: "pages/crews/_crewid/safety-sheets/add" */))
const _70a5b415 = () => interopDefault(import('../pages/crews/_crewid/systems-audit/new.vue' /* webpackChunkName: "pages/crews/_crewid/systems-audit/new" */))
const _0f9e2396 = () => interopDefault(import('../pages/employees/_id/forms/da-consent.vue' /* webpackChunkName: "pages/employees/_id/forms/da-consent" */))
const _42c69416 = () => interopDefault(import('../pages/employees/_id/forms/rol-request.vue' /* webpackChunkName: "pages/employees/_id/forms/rol-request" */))
const _78fa4835 = () => interopDefault(import('../pages/employees/_id/medical-information/edit.vue' /* webpackChunkName: "pages/employees/_id/medical-information/edit" */))
const _7516e85e = () => interopDefault(import('../pages/employees/_id/record-learning/request.vue' /* webpackChunkName: "pages/employees/_id/record-learning/request" */))
const _2a177eae = () => interopDefault(import('../pages/machines/_machineid/induction/edit.vue' /* webpackChunkName: "pages/machines/_machineid/induction/edit" */))
const _396e718f = () => interopDefault(import('../pages/machines/_machineid/prepurchase/new.vue' /* webpackChunkName: "pages/machines/_machineid/prepurchase/new" */))
const _2d5f6be7 = () => interopDefault(import('../pages/sites/_siteid/emergency-information/edit.vue' /* webpackChunkName: "pages/sites/_siteid/emergency-information/edit" */))
const _288520a7 = () => interopDefault(import('../pages/sites/_siteid/enviromentalchecklist/new.vue' /* webpackChunkName: "pages/sites/_siteid/enviromentalchecklist/new" */))
const _377bd1e1 = () => interopDefault(import('../pages/sites/_siteid/hazard-id/edit.vue' /* webpackChunkName: "pages/sites/_siteid/hazard-id/edit" */))
const _7c7d5f66 = () => interopDefault(import('../pages/sites/_siteid/presite/new.vue' /* webpackChunkName: "pages/sites/_siteid/presite/new" */))
const _08a0825e = () => interopDefault(import('../pages/crews/_crewid/sbos/new/_formid.vue' /* webpackChunkName: "pages/crews/_crewid/sbos/new/_formid" */))
const _187ece18 = () => interopDefault(import('../pages/crews/_crewid/toolbox/new/_toolboxid.vue' /* webpackChunkName: "pages/crews/_crewid/toolbox/new/_toolboxid" */))
const _ba7b5826 = () => interopDefault(import('../pages/crews/_crewid/sbos/view/_formid/_id.vue' /* webpackChunkName: "pages/crews/_crewid/sbos/view/_formid/_id" */))
const _7dd78a0e = () => interopDefault(import('../pages/crews/_crewid/toolbox/view/_formid/_id.vue' /* webpackChunkName: "pages/crews/_crewid/toolbox/view/_formid/_id" */))
const _edc252e4 = () => interopDefault(import('../pages/contractors/_companyid/reviews/_id.vue' /* webpackChunkName: "pages/contractors/_companyid/reviews/_id" */))
const _6bfd5d81 = () => interopDefault(import('../pages/crews/_crewid/audits/_id.vue' /* webpackChunkName: "pages/crews/_crewid/audits/_id" */))
const _2b870a45 = () => interopDefault(import('../pages/crews/_crewid/emergency-drill/_drillid.vue' /* webpackChunkName: "pages/crews/_crewid/emergency-drill/_drillid" */))
const _a0e1ccec = () => interopDefault(import('../pages/crews/_crewid/safe-start-stop/_id.vue' /* webpackChunkName: "pages/crews/_crewid/safe-start-stop/_id" */))
const _b7d23050 = () => interopDefault(import('../pages/crews/_crewid/safety-meeting/_meetingid.vue' /* webpackChunkName: "pages/crews/_crewid/safety-meeting/_meetingid" */))
const _5cefdf2f = () => interopDefault(import('../pages/crews/_crewid/systems-audit/_id.vue' /* webpackChunkName: "pages/crews/_crewid/systems-audit/_id" */))
const _14cf4bc1 = () => interopDefault(import('../pages/sites/_siteid/enviromentalchecklist/_id.vue' /* webpackChunkName: "pages/sites/_siteid/enviromentalchecklist/_id" */))
const _a3e90932 = () => interopDefault(import('../pages/sites/_siteid/presite/_id.vue' /* webpackChunkName: "pages/sites/_siteid/presite/_id" */))
const _65a17dc8 = () => interopDefault(import('../pages/crews/_crewid/safety-review/_id/meeting.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/_id/meeting" */))
const _1a7202ee = () => interopDefault(import('../pages/crews/_crewid/safety-review/_id/meeting.unit.js' /* webpackChunkName: "pages/crews/_crewid/safety-review/_id/meeting.unit" */))
const _2579fe3a = () => interopDefault(import('../pages/crews/_crewid/safety-review/_id/_type.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/_id/_type" */))
const _41f6bd85 = () => interopDefault(import('../pages/contractors/_companyid/_id/index.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/index" */))
const _2ffcdc96 = () => interopDefault(import('../pages/sop/_id/_version.vue' /* webpackChunkName: "pages/sop/_id/_version" */))
const _742f2842 = () => interopDefault(import('../pages/contractors/_companyid/_id/view.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view" */))
const _0558f545 = () => interopDefault(import('../pages/contractors/_companyid/_id/view/index.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/index" */))
const _30a86fd9 = () => interopDefault(import('../pages/contractors/_companyid/_id/view/file.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/file" */))
const _bb373bbc = () => interopDefault(import('../pages/contractors/_companyid/_id/view/forms.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/forms" */))
const _7ba400ef = () => interopDefault(import('../pages/contractors/_companyid/_id/view/inductions.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/inductions" */))
const _66427b22 = () => interopDefault(import('../pages/contractors/_companyid/_id/worker-information/edit.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/worker-information/edit" */))
const _33202ff5 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/address-book",
    component: _8367ebdc,
    name: "address-book"
  }, {
    path: "/company",
    component: _25debde3,
    name: "company"
  }, {
    path: "/company-panel",
    component: _4d0edd1a,
    name: "company-panel"
  }, {
    path: "/contractors",
    component: _71b90978,
    name: "contractors"
  }, {
    path: "/crews",
    component: _50fffd18,
    name: "crews"
  }, {
    path: "/employees",
    component: _846e5faa,
    name: "employees"
  }, {
    path: "/hazard-register",
    component: _0f836724,
    name: "hazard-register"
  }, {
    path: "/incidents",
    component: _4ff0f387,
    name: "incidents"
  }, {
    path: "/login",
    component: _067059e8,
    name: "login"
  }, {
    path: "/machines",
    component: _85db6808,
    name: "machines"
  }, {
    path: "/reminders",
    component: _11b41832,
    name: "reminders"
  }, {
    path: "/safety-alerts",
    component: _3d919cd8,
    name: "safety-alerts"
  }, {
    path: "/settings",
    component: _48fddb5a,
    name: "settings"
  }, {
    path: "/sites",
    component: _6d1a4b72,
    name: "sites"
  }, {
    path: "/summary",
    component: _d8ca23ee,
    children: [{
      path: "",
      component: _19d5ec68,
      name: "summary"
    }, {
      path: "crews",
      component: _6c56242c,
      name: "summary-crews"
    }, {
      path: "incidents",
      component: _3ae1965b,
      name: "summary-incidents"
    }]
  }, {
    path: "/support",
    component: _6c5c89b2,
    name: "support"
  }, {
    path: "/training-competency",
    component: _12c01854,
    name: "training-competency"
  }, {
    path: "/vehicles",
    component: _fb52a012,
    name: "vehicles"
  }, {
    path: "/company-panel/view",
    component: _327a420d,
    children: [{
      path: "",
      component: _0dbe4ad0,
      name: "company-panel-view"
    }, {
      path: "edit-details",
      component: _b775a51e,
      name: "company-panel-view-edit-details"
    }, {
      path: "manage-access",
      component: _7e6ceccd,
      name: "company-panel-view-manage-access"
    }, {
      path: "manage-crews",
      component: _eef48988,
      name: "company-panel-view-manage-crews"
    }, {
      path: "manage-employees",
      component: _29e24d0f,
      name: "company-panel-view-manage-employees"
    }, {
      path: "manage-access/view",
      component: _3411effa,
      children: [{
        path: "",
        component: _5d75da06,
        name: "company-panel-view-manage-access-view"
      }, {
        path: "groups",
        component: _7af1a439,
        name: "company-panel-view-manage-access-view-groups"
      }]
    }]
  }, {
    path: "/company/view",
    component: _3136a424,
    children: [{
      path: "",
      component: _1a4f00b2,
      name: "company-view"
    }, {
      path: "files",
      component: _54de9268,
      name: "company-view-files"
    }, {
      path: "objectives",
      component: _ad3a7656,
      name: "company-view-objectives"
    }, {
      path: "policy",
      component: _c56a8da6,
      name: "company-view-policy"
    }]
  }, {
    path: "/employees/new",
    component: _1ac8b639,
    name: "employees-new"
  }, {
    path: "/incidents/new",
    component: _87cbfed6,
    name: "incidents-new"
  }, {
    path: "/machines/add",
    component: _3e814e6a,
    name: "machines-add"
  }, {
    path: "/safety-alerts/new",
    component: _61c6ca26,
    name: "safety-alerts-new"
  }, {
    path: "/sites/register",
    component: _1f429c9a,
    children: [{
      path: "",
      component: _1cc43414,
      name: "sites-register"
    }, {
      path: ":forestid",
      component: _42efd3fb,
      name: "sites-register-forestid"
    }]
  }, {
    path: "/vehicles/add",
    component: _36327f86,
    name: "vehicles-add"
  }, {
    path: "/reminders/goto/crewgear",
    component: _3143c2b1,
    name: "reminders-goto-crewgear"
  }, {
    path: "/reminders/goto/employeegear",
    component: _a8a15304,
    name: "reminders-goto-employeegear"
  }, {
    path: "/company-panel/manage-access/group/:groupuuid?",
    component: _3ac7a696,
    name: "company-panel-manage-access-group-groupuuid"
  }, {
    path: "/company-panel/manage-access/user/:useruuid?",
    component: _40644bac,
    name: "company-panel-manage-access-user-useruuid"
  }, {
    path: "/company/program/:versionid",
    component: _39947b21,
    name: "company-program-versionid"
  }, {
    path: "/company/program/:versionid?/policy",
    component: _2e0e2cf3,
    name: "company-program-versionid-policy"
  }, {
    path: "/employees/:id",
    component: _f3d044d4,
    name: "employees-id"
  }, {
    path: "/incidents/:id",
    component: _92f21a1c,
    name: "incidents-id"
  }, {
    path: "/sites/:siteid",
    component: _2372bea4,
    name: "sites-siteid"
  }, {
    path: "/summarynew/:drillid?",
    component: _eba8f2e2,
    name: "summarynew-drillid"
  }, {
    path: "/contractors/:companyid/agreement",
    component: _bc7c5456,
    name: "contractors-companyid-agreement"
  }, {
    path: "/contractors/:companyid/reviews",
    component: _26a73ab4,
    name: "contractors-companyid-reviews"
  }, {
    path: "/crews/:crewid/documents",
    component: _4f44a74a,
    children: [{
      path: "",
      component: _5b450e4d,
      name: "crews-crewid-documents"
    }, {
      path: "files",
      component: _3dfd4572,
      name: "crews-crewid-documents-files"
    }, {
      path: "gear-tracker",
      component: _f4a98d62,
      name: "crews-crewid-documents-gear-tracker"
    }, {
      path: "safe-start",
      component: _b7545492,
      name: "crews-crewid-documents-safe-start"
    }, {
      path: "substance-inv",
      component: _464c7b8f,
      name: "crews-crewid-documents-substance-inv"
    }]
  }, {
    path: "/crews/:crewid/monthly",
    component: _9ba12ec2,
    children: [{
      path: "",
      component: _ec66883c,
      name: "crews-crewid-monthly"
    }, {
      path: "audits",
      component: _286127d0,
      name: "crews-crewid-monthly-audits"
    }, {
      path: "emergency-drills",
      component: _02152754,
      name: "crews-crewid-monthly-emergency-drills"
    }, {
      path: "safety-meetings",
      component: _09ed72c3,
      name: "crews-crewid-monthly-safety-meetings"
    }, {
      path: "safety-review",
      component: _7d41d57a,
      name: "crews-crewid-monthly-safety-review"
    }, {
      path: "sbos",
      component: _618a5d13,
      name: "crews-crewid-monthly-sbos"
    }, {
      path: "system-audits",
      component: _341f8e66,
      name: "crews-crewid-monthly-system-audits"
    }, {
      path: "toolbox",
      component: _65027206,
      name: "crews-crewid-monthly-toolbox"
    }, {
      path: "toolbox/toolbox.unit",
      component: _9187b258,
      name: "crews-crewid-monthly-toolbox-toolbox.unit"
    }]
  }, {
    path: "/employees/:id/edit",
    component: _4c873af6,
    name: "employees-id-edit"
  }, {
    path: "/employees/:id/view",
    component: _34514211,
    children: [{
      path: "",
      component: _34239058,
      name: "employees-id-view"
    }, {
      path: "compliance",
      component: _1762cbee,
      name: "employees-id-view-compliance"
    }, {
      path: "drug-alcohol",
      component: _3660234f,
      name: "employees-id-view-drug-alcohol"
    }, {
      path: "files",
      component: _6eb3220e,
      name: "employees-id-view-files"
    }, {
      path: "forms",
      component: _fa0cb69e,
      name: "employees-id-view-forms"
    }, {
      path: "induction",
      component: _1ad9d9c3,
      name: "employees-id-view-induction"
    }, {
      path: "linked",
      component: _040787f2,
      name: "employees-id-view-linked"
    }, {
      path: "medical",
      component: _7f397f93,
      name: "employees-id-view-medical"
    }, {
      path: "ppe-tracker",
      component: _01cd6052,
      name: "employees-id-view-ppe-tracker"
    }, {
      path: "record-learning",
      component: _031b439c,
      name: "employees-id-view-record-learning"
    }, {
      path: "training-records",
      component: _621e7086,
      name: "employees-id-view-training-records"
    }]
  }, {
    path: "/incidents/:id/edit",
    component: _8d1bebcc,
    name: "incidents-id-edit"
  }, {
    path: "/machines/:machineid/edit",
    component: _11adadc0,
    name: "machines-machineid-edit"
  }, {
    path: "/machines/:machineid/files",
    component: _126bebd1,
    name: "machines-machineid-files"
  }, {
    path: "/machines/:machineid/induction",
    component: _5a9010de,
    name: "machines-machineid-induction"
  }, {
    path: "/machines/:machineid/prepurchase",
    component: _6c25e3fe,
    name: "machines-machineid-prepurchase"
  }, {
    path: "/sites/:siteid/view",
    component: _62af0743,
    children: [{
      path: "",
      component: _c9c860f4,
      name: "sites-siteid-view"
    }, {
      path: "covid-qr",
      component: _99ec6f76,
      name: "sites-siteid-view-covid-qr"
    }, {
      path: "emergency-information",
      component: _cb6daab8,
      name: "sites-siteid-view-emergency-information"
    }, {
      path: "files",
      component: _7dd406ab,
      name: "sites-siteid-view-files"
    }, {
      path: "hazard-id",
      component: _207b992c,
      name: "sites-siteid-view-hazard-id"
    }, {
      path: "more-forms",
      component: _ca19299a,
      name: "sites-siteid-view-more-forms"
    }, {
      path: "visitors",
      component: _0e318f41,
      name: "sites-siteid-view-visitors"
    }]
  }, {
    path: "/sites/:siteid/visitor",
    component: _73271480,
    children: [{
      path: "",
      component: _3ef9ac43,
      name: "sites-siteid-visitor"
    }, {
      path: "addressbook",
      component: _510887e4,
      name: "sites-siteid-visitor-addressbook"
    }, {
      path: "contractor",
      component: _622f6f58,
      name: "sites-siteid-visitor-contractor"
    }, {
      path: "infrequent",
      component: _58e6bc5c,
      name: "sites-siteid-visitor-infrequent"
    }]
  }, {
    path: "/vehicles/:vehicleid/edit",
    component: _2a0d7356,
    name: "vehicles-vehicleid-edit"
  }, {
    path: "/contractors/:companyid/reviews/new",
    component: _c656a918,
    name: "contractors-companyid-reviews-new"
  }, {
    path: "/crews/:crewid/audits/new",
    component: _7fb33267,
    name: "crews-crewid-audits-new"
  }, {
    path: "/crews/:crewid/emergency-drill/new",
    component: _372aee7c,
    name: "crews-crewid-emergency-drill-new"
  }, {
    path: "/crews/:crewid/safe-start-stop/new",
    component: _79762320,
    name: "crews-crewid-safe-start-stop-new"
  }, {
    path: "/crews/:crewid/safety-meeting/new",
    component: _890782a2,
    name: "crews-crewid-safety-meeting-new"
  }, {
    path: "/crews/:crewid/safety-review/health-representative-review",
    component: _e6ab85de,
    name: "crews-crewid-safety-review-health-representative-review"
  }, {
    path: "/crews/:crewid/safety-review/new",
    component: _16944654,
    name: "crews-crewid-safety-review-new"
  }, {
    path: "/crews/:crewid/safety-review/site-supervisor-review",
    component: _3fbd9211,
    name: "crews-crewid-safety-review-site-supervisor-review"
  }, {
    path: "/crews/:crewid/safety-review/worker-review",
    component: _1c967ebd,
    name: "crews-crewid-safety-review-worker-review"
  }, {
    path: "/crews/:crewid/safety-sheets/add",
    component: _18883ef3,
    name: "crews-crewid-safety-sheets-add"
  }, {
    path: "/crews/:crewid/systems-audit/new",
    component: _70a5b415,
    name: "crews-crewid-systems-audit-new"
  }, {
    path: "/employees/:id/forms/da-consent",
    component: _0f9e2396,
    name: "employees-id-forms-da-consent"
  }, {
    path: "/employees/:id/forms/rol-request",
    component: _42c69416,
    name: "employees-id-forms-rol-request"
  }, {
    path: "/employees/:id/medical-information/edit",
    component: _78fa4835,
    name: "employees-id-medical-information-edit"
  }, {
    path: "/employees/:id/record-learning/request",
    component: _7516e85e,
    name: "employees-id-record-learning-request"
  }, {
    path: "/machines/:machineid/induction/edit",
    component: _2a177eae,
    name: "machines-machineid-induction-edit"
  }, {
    path: "/machines/:machineid/prepurchase/new",
    component: _396e718f,
    name: "machines-machineid-prepurchase-new"
  }, {
    path: "/sites/:siteid/emergency-information/edit",
    component: _2d5f6be7,
    name: "sites-siteid-emergency-information-edit"
  }, {
    path: "/sites/:siteid/enviromentalchecklist/new",
    component: _288520a7,
    name: "sites-siteid-enviromentalchecklist-new"
  }, {
    path: "/sites/:siteid/hazard-id/edit",
    component: _377bd1e1,
    name: "sites-siteid-hazard-id-edit"
  }, {
    path: "/sites/:siteid/presite/new",
    component: _7c7d5f66,
    name: "sites-siteid-presite-new"
  }, {
    path: "/crews/:crewid/sbos/new/:formid?",
    component: _08a0825e,
    name: "crews-crewid-sbos-new-formid"
  }, {
    path: "/crews/:crewid/toolbox/new/:toolboxid?",
    component: _187ece18,
    name: "crews-crewid-toolbox-new-toolboxid"
  }, {
    path: "/crews/:crewid/sbos/view/:formid?/:id?",
    component: _ba7b5826,
    name: "crews-crewid-sbos-view-formid-id"
  }, {
    path: "/crews/:crewid/toolbox/view/:formid?/:id?",
    component: _7dd78a0e,
    name: "crews-crewid-toolbox-view-formid-id"
  }, {
    path: "/contractors/:companyid/reviews/:id",
    component: _edc252e4,
    name: "contractors-companyid-reviews-id"
  }, {
    path: "/crews/:crewid/audits/:id?",
    component: _6bfd5d81,
    name: "crews-crewid-audits-id"
  }, {
    path: "/crews/:crewid/emergency-drill/:drillid?",
    component: _2b870a45,
    name: "crews-crewid-emergency-drill-drillid"
  }, {
    path: "/crews/:crewid/safe-start-stop/:id?",
    component: _a0e1ccec,
    name: "crews-crewid-safe-start-stop-id"
  }, {
    path: "/crews/:crewid/safety-meeting/:meetingid?",
    component: _b7d23050,
    name: "crews-crewid-safety-meeting-meetingid"
  }, {
    path: "/crews/:crewid/systems-audit/:id?",
    component: _5cefdf2f,
    name: "crews-crewid-systems-audit-id"
  }, {
    path: "/sites/:siteid/enviromentalchecklist/:id?",
    component: _14cf4bc1,
    name: "sites-siteid-enviromentalchecklist-id"
  }, {
    path: "/sites/:siteid/presite/:id?",
    component: _a3e90932,
    name: "sites-siteid-presite-id"
  }, {
    path: "/crews/:crewid/safety-review/:id?/meeting",
    component: _65a17dc8,
    name: "crews-crewid-safety-review-id-meeting"
  }, {
    path: "/crews/:crewid/safety-review/:id?/meeting.unit",
    component: _1a7202ee,
    name: "crews-crewid-safety-review-id-meeting.unit"
  }, {
    path: "/crews/:crewid/safety-review/:id?/:type?",
    component: _2579fe3a,
    name: "crews-crewid-safety-review-id-type"
  }, {
    path: "/contractors/:companyid/:id",
    component: _41f6bd85,
    name: "contractors-companyid-id"
  }, {
    path: "/sop/:id?/:version?",
    component: _2ffcdc96,
    name: "sop-id-version"
  }, {
    path: "/contractors/:companyid/:id?/view",
    component: _742f2842,
    children: [{
      path: "",
      component: _0558f545,
      name: "contractors-companyid-id-view"
    }, {
      path: "file",
      component: _30a86fd9,
      name: "contractors-companyid-id-view-file"
    }, {
      path: "forms",
      component: _bb373bbc,
      name: "contractors-companyid-id-view-forms"
    }, {
      path: "inductions",
      component: _7ba400ef,
      name: "contractors-companyid-id-view-inductions"
    }]
  }, {
    path: "/contractors/:companyid/:id?/worker-information/edit",
    component: _66427b22,
    name: "contractors-companyid-id-worker-information-edit"
  }, {
    path: "/",
    component: _33202ff5,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
