<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      draftPhoto: null,
      cameras: [],
      currentCamera: null,
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    cameraAvailable() {
      return this.currentCamera !== null
    },
  },
  methods: {
    takePhoto() {
      this.draftPhoto = this.$refs.camera.capture()
    },
    changeCamera() {
      const cameraArr = this.cameras.filter(
        (x) => x.deviceId !== this.currentCamera
      )
      if (cameraArr.length === 1) {
        this.$refs.camera.changeCamera(cameraArr[0].deviceId)
      }
    },
    addPhoto() {
      this.$emit('photo', this.draftPhoto)
      this.showDialog = false
      this.draftPhoto = null
    },
  },
}
</script>

<template>
  <v-dialog v-model="showDialog" :persistent="draftPhoto !== null">
    <v-overlay opacity="1">
      <v-card>
        <div v-if="draftPhoto === null">
          <div class="d-flex align-center justify-center" style="height: 90vh">
            <div class="text-center">
              <h2 v-if="!cameraAvailable">No Camera</h2>
              <vue-web-cam
                v-if="showDialog"
                ref="camera"
                width="90%"
                height="90%"
                select-first-device
                @cameras="cameras = $event"
                @camera-change="currentCamera = $event"
              />
            </div>
            <v-bottom-navigation grow dark fixed>
              <v-btn @click="showDialog = false">
                <span>Close</span>
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer />

              <v-btn
                :disabled="!cameraAvailable"
                color="blue"
                @click="takePhoto"
              >
                <v-icon :size="40"> mdi-camera-iris </v-icon>
              </v-btn>
              <v-spacer />

              <v-btn
                :disabled="!cameraAvailable || cameras.length < 2"
                @click="changeCamera"
              >
                <span>Flip Camera</span>
                <v-icon>mdi-shuffle-variant</v-icon>
              </v-btn>
            </v-bottom-navigation>
          </div>
        </div>
        <div v-else>
          <div style="background-color: #000">
            <div class="d-flex align-center justify-center">
              <img :src="draftPhoto" />
            </div>
            <v-bottom-navigation grow dark fixed>
              <v-btn @click="draftPhoto = null">
                <span>Retake</span>
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-spacer />

              <v-btn @click="addPhoto">
                <span>Add Photo</span>
                <v-icon>mdi-plus-box</v-icon>
              </v-btn>
            </v-bottom-navigation>
          </div>
        </div>
      </v-card>
    </v-overlay>
  </v-dialog>
</template>
